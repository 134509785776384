import {createApp} from "vue"
import {createI18n} from 'vue-i18n'
import {createPinia} from "pinia"
import SearchAutocomplete from "./components/search/SearchAutocomplete.vue";

const pinia = createPinia()

const cId = ((id) => {
    return document.getElementById(id)
})

if(cId('searchAutocomplete')) {
    createApp(SearchAutocomplete).mount('#searchAutocomplete');
}