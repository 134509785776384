<template>
  <div id="ws-search-container">
    <form class="uk-search uk-search-navbar uk-width-1-1" action="/search">
      <span class="uk-search-icon-flip" uk-search-icon></span>
      <input id="searchToggle" v-model="searchTerm" name="term" class="uk-width-1-1 uk-search-input" @keyup="fetchSearchResults" type="search"
          :placeholder="'Artikelnr. / Produkt eingeben'" autocomplete="off" autofocus/>
    </form>
  </div>
  <div v-show="searchResultList.length > 0">
    <div id="ws-search-ac" class="uk-dropdown" uk-drop="boundary-x: #ws-head-container; toggle: #searchToggle; stretch: x; mode: click; offset: 30">
      <div v-for="(item, key) in searchResultList" :key="item.Id">
        <div class="ws-search-ac-item-container uk-grid-medium uk-position-relative" uk-grid>
          <div class="uk-width-auto" v-html="item.imageHtml"></div>
          <div class="uk-width-expand">
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-1-1 uk-width-auto@m">
                <h3 class="uk-margin-remove-bottom">
                  <span v-html="item.name"></span><span v-if="item.articleNumber">&nbsp;|&nbsp;<span v-html="item.articleNumber"></span></span>
                </h3>
                <span class="uk-text-nowrap uk-margin-medium-right" v-for="cs in item.csData"><span>{{ cs.keyName }}</span> <span v-html="cs.highlightText"></span></span>
              </div>
              <div class="uk-width-1-1 uk-width-expand@m" v-html="item.descShort"></div>
            </div>
          </div>
          <a v-if="item.link" :href="item.link" class="uk-position-cover" :title="item.name"></a>
        </div>
        <hr v-if="key < (searchResultList.length -1)" class="uk-hr">
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../service/ApiService";
import {drop} from "uikit";

export default {
  name: "SearchAutocomplete",
  data() {
    return {
      searchTerm: '',
      searchResultList: [],
      timer: null
    }
  },
  methods: {
    fetchSearchResults() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        ApiService.getProductSearchResult({term: this.searchTerm}).then(response => {
          this.searchResultList = response.data
          if (this.searchResultList.length > 0) {
            drop('#ws-search-ac').show()
          }
        })

      }, 250)
    }
  },
  computed: {
    showDropDown() {
      return this.searchResultList.length > 0
    }
  }
}
</script>
