import axios from "axios";

const apiClient = axios.create({
    baseUrl: baseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});

export default {
    getProductSearchResult(payload) {
        return apiClient.post('/search?autocomplete=1&term=' + payload.term)
    }
}